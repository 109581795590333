.App {
  text-align: center;
  height: 100%;
}

html {
  height: 100%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.hover {
  position: relative;
}

.atm9 {
  box-shadow: 1px 5px 10px black;
}

p {
  margin: 0px;
}

.floaty {
  animation-name: fade;
  animation-duration: 2s;
  box-shadow: 10px 5px 20px black;
  border-radius: 10px;
  padding: 2rem;
  background-color: rgba(255, 255, 255, 0.92);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@keyframes fade {
  from {
    opacity: 0%;
  }
  to {
    opacity: 100%
  }
}
